import {BufferHealthStatus} from '../Constants/BufferHealthStatus';
import type {Timing} from '../Models/Timing';

const bufferHealthStatusMachine = (timing: Timing): BufferHealthStatus => {
    const {
        bufferHealthStatus: prevBufferHealthStatus,
        combinedBufferAheadDurationSeconds,
        healthyBufferThresholdSeconds,
        unhealthyBufferThresholdSeconds,
        criticalBufferThresholdSeconds,
    } = timing;

    switch (prevBufferHealthStatus) {
        case BufferHealthStatus.EMPTY:
            if (combinedBufferAheadDurationSeconds >= healthyBufferThresholdSeconds) {
                return BufferHealthStatus.HEALTHY;
            } else if (combinedBufferAheadDurationSeconds >= unhealthyBufferThresholdSeconds) {
                return BufferHealthStatus.UNHEALTHY;
            } else if (combinedBufferAheadDurationSeconds >= 0) {
                return BufferHealthStatus.CRITICAL;
            }

            return prevBufferHealthStatus;
        case BufferHealthStatus.CRITICAL:
            if (combinedBufferAheadDurationSeconds >= healthyBufferThresholdSeconds) {
                return BufferHealthStatus.HEALTHY;
            } else if (combinedBufferAheadDurationSeconds >= unhealthyBufferThresholdSeconds) {
                return BufferHealthStatus.UNHEALTHY;
            } else if (combinedBufferAheadDurationSeconds === 0) {
                return BufferHealthStatus.EMPTY;
            }

            return prevBufferHealthStatus;

        case BufferHealthStatus.HEALTHY:
            if (combinedBufferAheadDurationSeconds === 0) {
                return BufferHealthStatus.EMPTY;
            } else if (combinedBufferAheadDurationSeconds < criticalBufferThresholdSeconds) {
                return BufferHealthStatus.CRITICAL;
            } else if (combinedBufferAheadDurationSeconds < unhealthyBufferThresholdSeconds) {
                return BufferHealthStatus.UNHEALTHY;
            }

            return prevBufferHealthStatus;
        case BufferHealthStatus.UNHEALTHY:
            if (combinedBufferAheadDurationSeconds >= healthyBufferThresholdSeconds) {
                return BufferHealthStatus.HEALTHY;
            } else if (combinedBufferAheadDurationSeconds === 0) {
                return BufferHealthStatus.EMPTY;
            } else if (combinedBufferAheadDurationSeconds < criticalBufferThresholdSeconds) {
                return BufferHealthStatus.CRITICAL;
            }

            return prevBufferHealthStatus;
        default:
            return prevBufferHealthStatus;
    }
};

export {bufferHealthStatusMachine};
